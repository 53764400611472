// Generated by Framer (23a204f)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, RichText, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion } from "framer-motion";
import * as React from "react";
import * as sharedStyle from "../css/pS3CWnIh5";
import * as sharedStyle1 from "../css/xZndidUCt";

const cycleOrder = ["BEGppW1Nx"];

const variantClassNames = {BEGppW1Nx: "framer-v-zmk8v2"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const humanReadableVariantMap = {};

const transitions = {default: {damping: 60, delay: 0, duration: 0.3, ease: [0.44, 0, 0.56, 1], mass: 1, stiffness: 500, type: "spring"}};

const numberToString = (value, options = {}) => {
    const fallbackLocale = "en-US";
    const {
        locale = fallbackLocale,
        useGrouping,
        notation,
        compactDisplay,
        style,
        currency,
        currencyDisplay,
        unit,
        unitDisplay,
        minimumFractionDigits,
        maximumFractionDigits,
        minimumIntegerDigits,
    } = options;
    const formatOptions = {useGrouping, notation, compactDisplay, style, currency, currencyDisplay, unit, unitDisplay, minimumFractionDigits, maximumFractionDigits, minimumIntegerDigits};
    const number = Number(value);
    try {
        return number.toLocaleString(locale, formatOptions);
    } catch {
        try {
            return number.toLocaleString(fallbackLocale, formatOptions);
        } catch {
            return number.toLocaleString();
        }
    }
};


export interface Props extends React.HTMLAttributes<HTMLDivElement> { layoutId?: string | null; width?: number; height?: number; layoutDependency?: string | number; variant?: keyof typeof humanReadableVariantMap; label?: string; background?: string; textColor?: string; count?: number }

const Component = React.forwardRef<HTMLDivElement, Props>(function({ id, style, className, width, height, layoutId, variant: outerVariant = "BEGppW1Nx", label: Y830noYls = "Breakpoints", background: dAvDl_gMs = "var(--token-aac37702-4bfb-4a44-9cb0-39598c0cc8a6, rgb(46, 46, 46)) /* {\"name\":\"Dark\"} */", textColor: ABTHudXq8 = "var(--token-1aa7a1f9-664a-40d1-a64a-074a2f9865c7, rgb(255, 255, 255))", count: oMsbs7mdh = 5, ...restProps }, ref) {

const outerVariantId = humanReadableVariantMap[outerVariant];

const variant = outerVariantId || outerVariant;

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "BEGppW1Nx", transitions, variant, variantClassNames})
const layoutDependency = variants.join("-") + restProps.layoutDependency;

const defaultLayoutId = React.useId()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-wjcox", sharedStyle.className, sharedStyle1.className, classNames)} style={{display: "contents"}}>
<motion.div {...restProps} className={cx("framer-zmk8v2", className)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"BEGppW1Nx"} ref={ref} style={{backgroundColor: dAvDl_gMs, ...style}} transition={transition}><RichText __fromCanvasComponent children={<React.Fragment><motion.h2 className={"framer-styles-preset-yuf2sj"} data-styles-preset={"pS3CWnIh5"} style={{"--framer-text-color": "var(--extracted-1of0zx5)"}}>NaN</motion.h2></React.Fragment>} className={"framer-q66wf6"} layoutDependency={layoutDependency} layoutId={"IxgExScFR"} style={{"--extracted-1of0zx5": "var(--variable-reference-ABTHudXq8-jH6v7ALcV)", "--framer-link-text-color": "rgb(0, 153, 255)", "--framer-link-text-decoration": "underline", "--framer-paragraph-spacing": "0px", "--variable-reference-ABTHudXq8-jH6v7ALcV": ABTHudXq8}} text={numberToString(oMsbs7mdh, {locale: "", notation: "standard", style: "decimal"})} transition={transition} verticalAlignment={"top"} withExternalLayout/><RichText __fromCanvasComponent children={<React.Fragment><motion.p className={"framer-styles-preset-21ogod"} data-styles-preset={"xZndidUCt"} style={{"--framer-text-color": "var(--extracted-r6o4lv)"}}>Breakpoints</motion.p></React.Fragment>} className={"framer-1r7o547"} layoutDependency={layoutDependency} layoutId={"VTpTSVk9y"} style={{"--extracted-r6o4lv": "var(--variable-reference-ABTHudXq8-jH6v7ALcV)", "--framer-link-text-color": "rgb(0, 153, 255)", "--framer-link-text-decoration": "underline", "--framer-paragraph-spacing": "0px", "--variable-reference-ABTHudXq8-jH6v7ALcV": ABTHudXq8}} text={Y830noYls} transition={transition} verticalAlignment={"top"} withExternalLayout/></motion.div>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-wjcox [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-wjcox .framer-1u9e9mx { display: block; }", ".framer-wjcox .framer-zmk8v2 { align-content: center; align-items: center; display: flex; flex-direction: column; flex-wrap: nowrap; gap: 8px; height: min-content; justify-content: center; overflow: hidden; padding: 36px 48px 48px 48px; position: relative; width: 422px; }", ".framer-wjcox .framer-q66wf6, .framer-wjcox .framer-1r7o547 { flex: none; height: auto; position: relative; white-space: pre; width: auto; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-wjcox .framer-zmk8v2 { gap: 0px; } .framer-wjcox .framer-zmk8v2 > * { margin: 0px; margin-bottom: calc(8px / 2); margin-top: calc(8px / 2); } .framer-wjcox .framer-zmk8v2 > :first-child { margin-top: 0px; } .framer-wjcox .framer-zmk8v2 > :last-child { margin-bottom: 0px; } }", ...sharedStyle.css, ...sharedStyle1.css]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 180
 * @framerIntrinsicWidth 422
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","auto"]}}}
 * @framerVariables {"Y830noYls":"label","dAvDl_gMs":"background","ABTHudXq8":"textColor","oMsbs7mdh":"count"}
 */
const FramerjH6v7ALcV: React.ComponentType<Props> = withCSS(Component, css, "framer-wjcox") as typeof Component;
export default FramerjH6v7ALcV;

FramerjH6v7ALcV.displayName = "Number";

FramerjH6v7ALcV.defaultProps = {height: 180, width: 422};

addPropertyControls(FramerjH6v7ALcV, {Y830noYls: {defaultValue: "Breakpoints", displayTextArea: false, title: "Label", type: ControlType.String}, dAvDl_gMs: {defaultValue: "var(--token-aac37702-4bfb-4a44-9cb0-39598c0cc8a6, rgb(46, 46, 46)) /* {\"name\":\"Dark\"} */", title: "Background", type: ControlType.Color}, ABTHudXq8: {defaultValue: "var(--token-1aa7a1f9-664a-40d1-a64a-074a2f9865c7, rgb(255, 255, 255))", title: "Text Color", type: ControlType.Color}, oMsbs7mdh: {defaultValue: 5, title: "Count", type: ControlType.Number}} as any)

addFonts(FramerjH6v7ALcV, [...sharedStyle.fonts, ...sharedStyle1.fonts])